<template>
  <div>
    <b-overlay
      :show="showOverlay && isNewFormType"
      class="form__overlay"
      rounded="sm"
      opacity="0.9"
      variant="light"
    >
      <template v-slot:overlay>
        <h4 class="text-red text-uppercase text-center">
          {{ $t("page.stimulus.noMoreVideosAllowedMsg") }}
        </h4>
      </template>
      <div
        v-if="!loadingVideo && !showVideoCaption && formType === 'NEW'"
        class="upload-section-wrapper rounded bg-gray-light"
        :class="{ 'custom-shadow': dragoverActive }"
      >
        <div
          class="upload-section rounded h-100"
          @drop.prevent="uploadLocalFile"
          @dragover.prevent="dragover"
          @dragenter="dragoverActive = true"
          @dragleave="dragoverActive = false"
        >
          <input
            :accept="getMimeTypes"
            ref="inputUpload"
            type="file"
            name="file-input"
            :disabled="!isUploadValid"
            @change="uploadLocalFile"
          />
          <b-row
            class="no-gutters h-100 justify-content-center"
            align-v="center"
          >
            <b-col class="col-12 text-center">
              <img
                src="./../../assets/general-icons/upload-alt.svg"
                class="upload__icon"
              />
              <p class="mb-0 font-weight-bold">{{ dropFileMsg }}</p>
              <p class="mb-1">{{ $t("or") }}</p>
              <b-button
                variant="outline-secondary"
                class="mx-auto"
                size="sm"
                @click="openUploadDialog"
              >
                {{ $t("button.chooseFile") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else class="video-preview">
        <b-row
          v-if="loadingVideo"
          class="no-gutters h-100 w-100 bg-red"
          align-v="center"
        >
          <b-col class="col-12 text-center text-white">
            <b-icon
              icon="arrow-clockwise"
              animation="spin-pulse"
              font-scale="4"
              variant="white"
            ></b-icon>
            <div class="text-uppercase">
              {{ $t("loading") }}
            </div>
          </b-col>
        </b-row>
        <video
          class="w-100 h-100"
          preload="metadata"
          ref="video"
          hidden
          @canplay="onVideoLoad"
          :src="`${fileInfo.src}#t=1.5`"
        ></video>
        <canvas hidden ref="canvas"></canvas>
      </div>
      <b-form>
        <p class="mt-3 mb-1 title__3">
          {{ $t("videoName") }}
        </p>
        <b-form-input v-model="fileInfo.name"></b-form-input>
        <p class="mt-3 mb-1 title__3">
          {{ $t("page.stimulus.videoCategoryQuestion") }}
        </p>
        <b-form-select class="input-field" v-model="selectedVideoCategory">
          <template v-slot:first>
            <b-form-select-option :value="''" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
          </template>
          <b-form-select-option
            v-for="(categoryInfo, index) in videoCategoryOptions"
            :key="index"
            :value="categoryInfo.name"
          >
            {{ categoryInfo.name }}
          </b-form-select-option>
        </b-form-select>
        <p class="mt-3 mb-1 title__3">
          {{ $t("page.stimulus.videoContentQuestion") }}
        </p>
        <b-form-radio-group
          v-model="selectedContentType"
          name="radio-content-type"
          class="mb-2"
        >
          <b-row class="no-gutters">
            <b-card
              v-for="(contentTypeItem, index) in contentTypeOptions"
              :key="index"
              class="p-1 mr-1 mb-1"
              :border-variant="
                isContentTypeSelected(contentTypeItem.value) ? 'primary' : ''
              "
              no-body
            >
              <b-form-radio
                :value="contentTypeItem.value"
                class="subtitle__1 mt-1"
              >
                {{ $t(contentTypeItem.text) }}
              </b-form-radio>
            </b-card>
          </b-row>
        </b-form-radio-group>
        <p class="mt-3 mb-1 title__3">
          {{ $t("page.stimulus.videoDevStageQuestion") }}
        </p>
        <b-form-radio-group
          v-model="fileInfo.status"
          name="radio-development-stage"
          class="mb-3"
        >
          <b-row class="no-gutters">
            <b-card
              v-for="(developmentStageItem, index) in developmentStageOptions"
              :key="index"
              class="p-1 mr-1 mb-1"
              :border-variant="
                isDevelopmentStageSelected(developmentStageItem.value)
                  ? 'primary'
                  : ''
              "
              no-body
            >
              <b-form-radio
                :value="developmentStageItem.value"
                class="subtitle__1 mt-1"
              >
                {{ $t(developmentStageItem.text) }}
              </b-form-radio>
            </b-card>
          </b-row>
        </b-form-radio-group>
        <b-row class="no-gutters justify-content-end align-items-center">
          <b-button
            v-if="formType === 'EDIT'"
            class="mr-1 mb-1 button--dark-border"
            variant="outline-dark"
            @click="cancelEditAction"
          >
            {{ $t("button.cancel") }}
          </b-button>
          <b-button class="mb-1" :disabled="!isFormValid" @click="sumbit">
            {{ $t(submitBtnLabel) }}
          </b-button>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import Utils from "../../Utils";
export default {
  props: {
    totalVideos: {
      type: Number,
      required: true
    },
    formType: {
      type: String,
      required: false,
      default: "NEW"
    },
    activeFile: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  watch: {
    formType: {

      handler: function() {
        if (this.formType === "EDIT") this.fileInfo = { ...this.activeFile };
        else if (this.formType === "NEW") this.resetUploadForm();
      },
      immediate: false
    },
    totalVideos:{
      handler: function(newTotalVideos){
        if(newTotalVideos > 1 && this.isClientCustom){
          this.$store.commit("wizard/SET_ACTIVE_PROJECT_PACKAGE",Utils.PROJECT_PACKAGE.CUSTOM_SEQUENTIAL);
          this.$store.commit("project/SET_PROJECT_TYPE",Utils.PROJECT_PACKAGE.CUSTOM_SEQUENTIAL)
        }else if(newTotalVideos <=1 && this.isClientCustom){
          this.$store.commit("wizard/SET_ACTIVE_PROJECT_PACKAGE",Utils.PROJECT_PACKAGE.CUSTOM);
          this.$store.commit("project/SET_PROJECT_TYPE",Utils.PROJECT_PACKAGE.CUSTOM)
        }
      },
      immediate: false
    }
  },
  data() {
    return {
      loadingVideo: false,
      showVideoCaption: false,
      dragoverActive: false,
      fileInfo: {},
      thumbnailBase64: "",
      scale: 0.25,
      videosAdded: 0,
      mimeTypes: ["video/mp4", "video/quicktime"]
    };
  },
  created() {
    this.resetStimulusInfo();
  },
  methods: {
    getRequiredRespondents() {
      const projectInfo = this.$store.getters[
        "wizard/getActiveProjectPackageInfo"
      ];
      switch (projectInfo.name) {
        case "Discover":
        case "A/B Test":
          return projectInfo.totalRespondents / projectInfo.totalVideo;
        case "Custom":
          return projectInfo.totalRespondents;
        default:
          return 0;
      }
    },
    resetStimulusInfo() {
      this.fileInfo = {
        _id: null,
        name: "",
        category: "",
        type: "",
        contentTypeOther: "",
        status: "",
        requiredRespondents: this.getRequiredRespondents(),
        file: {},
        src: null
      };
    },
    resetUploadForm() {
      this.resetStimulusInfo();
      this.loadingVideo = false;
      this.showVideoCaption = false;
      this.$refs.video.hidden = true;
    },
    sumbit() {
      if (this.isNewFormType) this.addVideo();
      else this.updateVideo();
    },
    uploadLocalFile(e) {
      const src = e.dataTransfer || e.target;
      const uploadedFiles = src.files;
      if (!uploadedFiles) return;
      else if (uploadedFiles.length > 1) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.manyUploadedFiles.text"
        });
        return;
      }

      const MAX_MB_SIZE = 50;
      if (uploadedFiles[0].size / Math.pow(1024, 2) > MAX_MB_SIZE) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.fileSizeExceed.text",
          messageParams: { value: MAX_MB_SIZE }
        });
        return;
      }

      this.fileInfo.file = uploadedFiles[0];

      if (!this.isVideo(this.fileInfo.file.type)) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.invalidFileFormat.text"
        });
        return;
      }

      this.dragoverActive = false;
      this.loadingVideo = true;
      this.setDataURL();
    },
    openUploadDialog() {
      this.$refs.inputUpload.click();
    },
    dragover(event) {
      event.dataTransfer.dropEffect = "move";
    },
    addVideo() {
      this.$store.commit("loader/SET_LOADER", {
        messageText: "dialog.loader.uploadVideo"
      });
      const video = { ...this.fileInfo };
      const totalVideos = this.totalVideos;
      this.$store
        .dispatch("stimulus/createProjectAsset", video)
        .then(() => {
          if (totalVideos === 0)
            return this.$store.dispatch("project/setProjectThumbnail");
        })
        .then(() => {
          this.resetUploadForm();
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    updateVideo() {
      const video = { ...this.fileInfo };
      this.$store
        .dispatch("stimulus/updateProjectAsset", video)
        .then(() => {
          this.$emit("updateVideo", { ...this.fileInfo });
          this.resetUploadForm();
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    setDataURL() {
      this.fileInfo.src = URL.createObjectURL(this.fileInfo.file);
      this.$nextTick(() => {
        this.$refs.video.onload = function() {
          URL.revokeObjectURL(this.fileInfo.src);
        };
      });
      // const reader = new FileReader();
      // reader.onload = e => {
      //   this.fileInfo.src = e.target.result;
      // };
      // reader.readAsDataURL(this.fileInfo.file);
    },
    onVideoLoad() {
      this.loadingVideo = false;

      const maxVideoMinutes = this.$store.getters[
        "wizard/getActiveProjectPackageInfo"
      ].maxMinute;

      if (this.$refs.video.duration > maxVideoMinutes * 60) {
        this.fileInfo.file = {};
        this.dragoverActive = false;
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.videoDurationLimit.text",
          messageParams: { minutes: maxVideoMinutes }
        });
        return;
      }

      this.showVideoCaption = true;

      this.$refs.video.hidden = false;
      if (this.isNewFormType) this.fileInfo._id = uniqueId("file_");

      if (this.totalVideos === 0) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.video;

        canvas.width = video.videoWidth; // * this.scale;
        canvas.height = video.videoHeight; // * this.scale;

        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        this.thumbnailBase64 = canvas.toDataURL();
        // this.videosAdded++;
        this.$store.commit(
          "project/SET_PROJECT_THUMBNAIL",
          this.base64ToFile(this.thumbnailBase64)
        );
      }
    },
    base64ToFile(dataURI) {
      var arr = dataURI.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `projectThumbnail.png`, { type: mime });
    },
    cancelEditAction() {
      return this.$emit("cancelEditAction");
    },
    isVideo(type) {
      return this.mimeTypes.includes(type);
    },
    isContentTypeSelected(contentType) {
      return this.fileInfo.type ? this.fileInfo.type === contentType : false;
    },
    isDevelopmentStageSelected(status) {
      return this.fileInfo.status ? this.fileInfo.status === status : false;
    }
  },
  computed: {
    totalVideos_formType(){
      return `${this.formType} | ${this.totalVideos}`;
    },
    getMimeTypes() {
      return this.mimeTypes.join(", ");
    },
    submitBtnLabel() {
      return this.formType === "NEW"
        ? "button.uploadVideo"
        : "button.saveChanges";
    },
    developmentStageOptions() {
      return this.$store.getters["stimulus/getDevelopmentStageOptions"];
    },
    contentTypeOptions() {
      return this.$store.getters["stimulus/getContentTypeOptions"];
    },
    videoCategoryOptions() {
      return this.$store.getters["stimulus/getCategoryOptions"];
    },
    showOverlay() {
      return !this.isUploadValid;
    },
    isNewFormType() {
      return this.formType === "NEW";
    },
    activeProjectPackage() {
      return this.$store.getters["wizard/getActiveProjectPackageInfo"];
    },
    isUploadValid() {
      if (!this.activeProjectPackage) return false;
      else if (this.activeProjectPackage.totalVideo === "-") return true;
      else if(this.isClientCustom) return true;
      else return this.activeProjectPackage.totalVideo > this.totalVideos;
    },
    dropFileMsg() {
      const file = this.$t("video");
      return this.$t("page.stimulus.dropFile", { file });
    },
    selectedVideoCategory: {
      get() {
        return this.fileInfo.category;
      },
      set(category) {
        this.fileInfo.category = category;
      }
    },
    selectedContentType: {
      get() {
        return this.fileInfo.type;
      },
      set(type) {
        this.fileInfo.type = type;
      }
    },
    isFormValid() {
      return (
        this.fileInfo._id !== null &&
        this.fileInfo.name.length > 0 &&
        this.fileInfo.category !== "" &&
        this.fileInfo.type !== "" &&
        this.fileInfo.status !== ""
      );
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    }
  }
};
</script>

<style scoped>
.upload-section-wrapper,
.video-preview {
  height: 300px;
}
.upload-section-wrapper {
  height: 300px;
  border: 2px dashed var(--gray);
  padding: 2px;
}
.custom-shadow {
  box-shadow: inset 0 0 20px 1px var(--gray);
}
.upload-section input {
  display: none;
}
.form__overlay {
  z-index: 0;
}

.upload__icon {
  width: 100px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}

.btn-outline-secondary {
  background-color: white;
  border-radius: 6px;
}
.btn-outline-secondary:hover {
  background-color: #6c757d;
}

.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::before,
.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::after {
  top: 0.1rem;
}

/* video {
  object-fit: fill;
  overflow: hidden;
} */
</style>
