<template>
  <b-card
    class="card-item h-100"
    no-body
    footer-bg-variant="white"
    footer-class="border-top-0 px-3"
  >
    <b-card-body class="p-0 bg-black">
      <video
        class="p-0"
        width="100%"
        height="100%"
        preload="metadata"
        :src="`${stimulus.src}#t=1.5`"
      ></video>
    </b-card-body>
    <template v-slot:footer>
      <b-row class="no-gutters mt-3">
        <b-col>
          <div class="mb-3 break-world">
            <p class="title__3">
              {{ stimulus.name | fileName }}
            </p>
            <p class="subtitle__1 mb-0">
              {{ $t(type) }}
            </p>
            <p class="subtitle__1">{{ $t(status) }}</p>
          </div>
        </b-col>
        <b-col class="col-auto">
          <img
            src="./../../assets/general-icons/pencil.svg"
            class="action__icon clickable"
            @click="editFile"
          />
        </b-col>
      </b-row>
      <hr class="my-2 mx-n3" />
      <b-row class="no-gutters justify-content-end">
        <b-col class="col-auto">
          <img
            src="./../../assets/general-icons/trash.svg"
            class="action__icon clickable"
            @click="removeFile"
          />
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
export default {
  watch: {
    imageData: {
      handler: function() {}
    }
  },
  props: {
    stimulus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    isVideo() {
      return (
        this.stimulus.file.type === "video/mp4" ||
        this.stimulus.file.type === "video/webm"
      );
    },
    isImg() {
      return this.file.type === "image/jpeg" || this.file.type === "image/png";
    },
    isReady() {
      return false;
    },
    contentTypeOptions() {
      return this.$store.getters["stimulus/getContentTypeOptions"];
    },
    developmentStageOptions() {
      return this.$store.getters["stimulus/getDevelopmentStageOptions"];
    },
    type() {
      const contentType = this.contentTypeOptions.find(
        contentType => contentType.value === this.stimulus.type
      );

      return contentType ? contentType.text : "Not available";
    },
    status() {
      const developmentStage = this.developmentStageOptions.find(
        developmentStage => developmentStage.value === this.stimulus.status
      );

      return developmentStage ? developmentStage.text : "Not available";
    }
  },
  methods: {
    editFile() {
      this.$emit("editFile", this.stimulus._id);
    },
    removeFile() {
      this.$emit("removeFile", this.stimulus._id);
    }
  }
};
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
}
video {
  /* width: 150px;
  height: 150px;
  object-fit: fill;
  overflow: hidden; */
  /* object-fit: fill; */
}

.break-world {
  word-break: break-all;
}

.card-item {
  border-width: 2px;
}

.action__icon {
  width: 20px;
  height: 20px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
