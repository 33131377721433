<template>
  <b-row class="no-gutters">
    <b-col class="col-12 col-md-8 col-lg-6">
      <h4
        class="font-weight-normal text-muted title__2"
        ref="uploadVideoSection"
      >
        {{ $t("page.stimulus.pageDescription") }}
      </h4>
      <b-card no-body class="px-3 pt-3 pb-5 mt-4">
        <h6 class="mb-0 text-muted">
          {{ $t("page.stimulus.mediaRequirementsMsg") }}
          <b-link
            class="link text-red"
            @click="requirementVisible = !requirementVisible"
          >
            {{ $t("page.stimulus.mediaRequirementsLink") }}
          </b-link>
        </h6>
        <b-collapse v-model="requirementVisible">
          <b-card-text class="bg-light p-3 mt-3">
            <div v-for="(item, index) in requirementList" :key="index">
              {{ $t(`page.stimulus.requirements.${item.text}`, item.params) }}
            </div>
          </b-card-text>
        </b-collapse>
        <hr class="mt-2 mb-4 mx-n3" />
        <video-form
          :totalVideos="files.length"
          :formType="formType"
          :activeFile="activeFile"
          @updateVideo="updateVideo"
          @cancelEditAction="cancelEditAction"
        />
      </b-card>
      <b-overlay
        :show="formType === 'EDIT'"
        class="list__overlay"
        rounded="sm"
        opacity="0.7"
        variant="light"
      >
        <template v-slot:overlay>
          <div></div>
        </template>
        <b-row class="upload-wrapper no-gutters">
          <b-col
            v-for="file in files"
            :key="file.id"
            class="col-12 col-md-6 col-xl-4 py-3 pr-0 pr-md-2"
          >
            <stimulus-list-item
              :stimulus="file"
              @editFile="editVideo"
              @removeFile="removeFile"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import StimulusListItem from "./../components/Stimulus/StimulusListItem";
import VideoForm from "./../components/Stimulus/VideoForm";
export default {
  components: {
    StimulusListItem,
    VideoForm
  },
  data() {
    return {
      formType: "NEW",
      activeFile: {},
      exampleVideo: null,
      requirementVisible: false
    };
  },
  computed: {
    files() {
      return this.$store.getters["stimulus/getStimulusList"];
    },
    maxVideoMinutes() {
      return this.$store.getters["wizard/getActiveProjectPackageInfo"]
        .maxMinute;
    },
    requirementList() {
      return [
        { text: "formatAccepted" },
        { text: "fileSize" },
        { text: "length", params: { minutes: this.maxVideoMinutes } },
        { text: "resolution" }
      ];
    }
  },
  methods: {
    editVideo(videoId) {
      this.formType = "EDIT";
      this.activeFile = this.files.find(f => {
        return f._id === videoId;
      });
      this.$nextTick(() => {
        this.$refs.uploadVideoSection.scrollIntoView({ behavior: "smooth" });
      });
    },
    updateVideo(video) {
      this.formType = "NEW";
    },
    cancelEditAction() {
      this.formType = "NEW";
    },
    removeFile(videoId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("stimulus/deleteProjectAsset", videoId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    }
  }
};
</script>

<style scoped>
.list__overlay {
  z-index: 0;
}
</style>
